import React, { ComponentPropsWithoutRef, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import * as Icon from "react-feather";
import { clsx } from 'clsx';

interface ITextInput extends ComponentPropsWithoutRef<"input"> {
    showIcon?: boolean,
    label?: string,
    showLabel?: boolean,
    type?: React.HTMLInputTypeAttribute | undefined
    value?: string | number | readonly string[] | undefined,
    hint?: string,
    area?: boolean,
    inputSize?: "sm" | "md" | "lg" | "xl" | "2xl",
    leadingText?: string,
    leadingIcon?: any,
    trailingIcon?: any
    onTrailingClick?: () => void
    error?: boolean,
}

export interface TextInputRef extends HTMLInputElement {
    value: string
}
export interface TextInputElement {
    getInputText: () => void,
    setValue: () => void,
    clearValue: () => void,
}

function TextInput({
    showIcon,
    label,
    showLabel,
    value,
    area,
    inputSize,
    leadingText,
    leadingIcon,
    trailingIcon,
    onTrailingClick,
    error,
    hint,
    type,
    ...rest
}: ITextInput, ref: any) {

    const sizes = {
        sm: 24,
        md: 32,
        lg: 32,
        xl: 32,
        "2xl": 32
    }


    // const inputRef = useRef<TextInputRef>()
    const inputRef = useRef<any>()
    const [inputValue, setInputValue] = useState<string>("")

    useImperativeHandle(ref, () => {
        return {
            getInputText: () => inputRef.current?.value,
            getLength: () => (inputRef.current?.value as string).length,
            setValue: (value: string) => {
                // setInputValue(value)
                if (inputRef.current !== undefined) {
                    inputRef.current.value = value
                }
            },
            clearValue: (value: string) => {
                // setInputValue(value)
                if (inputRef.current !== undefined) {
                    inputRef.current.value = ""
                }
            },
        }
    }, [inputRef.current]);

    return (
        <div className='relative'>
            <form className='relative'>
                {area ?
                    <div

                        className={clsx(
                            `flex flex-col w-max-[320px] `,
                            inputSize && `w-${(sizes[inputSize])}`, //if size specified, use specific size
                            !inputSize && "w-full" // else, fill container till max width
                        )}>
                        {(showLabel || label) && <label className='text-sm font-medium mb-2'>{label}</label>}
                        <textarea
                            {...rest}
                            ref={inputRef}
                            rows={4}
                            className="block border-gray-300 text-md p-2.5 w-full text-gray-900 rounded-lg border border-gray-300 focus:ring focus:ring-gray-200 focus:outline-none focus:border focus:border-gray-300 focus:border-2"
                        />
                        {hint && <div className='text-sm text-gray-500'>{hint}</div>
                        }
                    </div>
                    :
                    <>
                        {/* Leading and trailing */}
                        {leadingIcon && <i className='absolute top-1/2 transform -translate-y-1/2 left-3 z-10'>{leadingIcon}</i>}
                        {trailingIcon && <i onClick={onTrailingClick} className='absolute top-1/2 transform -translate-y-1/2 right-3 z-10'>{trailingIcon}</i>}

                        <div className={`relative flex flex-col w-max-[320px] w-full ${inputSize && `w-${(sizes[inputSize])}`}`}>
                            {(showLabel || label) && <label htmlFor='input' className='text-sm font-medium mb-2'>{label}</label>}

                            <input {...rest} pattern={type === "number" ? "[0-9]*" : undefined}
                                value={value}
                                inputMode={type === "number" ? "decimal" : undefined} id='input' ref={inputRef}
                                type={type}
                                className={clsx(
                                    `border rounded-lg border-gray-300 text-md text-gray-900 px-4 py-2 drop-shadow-sm focus:ring focus:ring-gray-200 focus:outline-none focus:border focus:border-gray-300 focus:border-2`,
                                    trailingIcon && "pr-10",
                                    leadingIcon && "pl-10"
                                )} />

                            {hint && <div className={`mt-1 text-sm ${error ? "text-error-500" : "text-gray-500"}`}>{hint}</div>}
                        </div>
                    </>
                }

            </form>
        </div>
    )
}

export default forwardRef(TextInput)