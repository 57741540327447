import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios';
import * as Icon from 'react-feather';
// import AnonymousUserImage from '../../assets/images/anonymous.jpeg'

import { Oval } from 'react-loader-spinner'
import Select from 'react-select';
import Creatable, { useCreatable } from 'react-select/creatable'
import { useIsMobile } from '../hooks/useIsMobile';
import { Button } from './button';
import { firestoreDb } from '../config/firebase.config';
import { doc, updateDoc } from 'firebase/firestore';
import { showToast, uploadFile } from '../utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../store/models/userModel';
import TextInput from './input/TextInput';
import { updateShopService } from '../utils/ShopServicesTasks';
import { shopSelector } from '../store/models/shopModel';
import ImageViewer from 'react-simple-image-viewer';
import Compressor from 'compressorjs'
import { professionalSelector } from '../store/models/professionalsModel';
import { Service } from '../types';
import { canAssignServiceToStaff, canEditService, canRemoveService } from '../utils/roles';
import { saveCategory } from '../api/api';

interface IServiceDetails {
    selectedService: any,
    categories: any
    onClose?: () => void,
    onPressDelete?: () => void,
    loadingDetails: boolean
    // onDidntShow: () => void,
    // onAccept: () => void,
    // onReject: () => void,
    // onActionError: () => void
}

function ServiceDetails({ selectedService, onClose, loadingDetails, onPressDelete }: IServiceDetails) {

    const { selectedShop, shopsData, categories } = useSelector(shopSelector)

    const [categorySelector, setCategorySelector] = useState<any>({})
    const [loading, setLoading] = useState(false)

    const [fetchedPictures, setFetchedPictures] = useState<any[]>([])

    const dispatch = useDispatch();

    const nameRef = useRef<any>()
    const priceRef = useRef<any>()
    const hoursRef = useRef<any>()
    const minutesRef = useRef<any>()
    const descriptionRef = useRef<any>()

    const [madeChanges, setMadeChanges] = useState<boolean>(false)

    const { proNames, proIDs } = useSelector(professionalSelector)
    const { roles } = useSelector(userSelector)

    useEffect(() => {
        setMadeChanges(false)
        // categoryRef.current.setValue(selectedService?.category)
        setCategorySelector({
            label: selectedService?.category,
            value: selectedService?.category
        })
        nameRef.current?.setValue(selectedService?.name)
        descriptionRef.current?.setValue(selectedService?.description.replace(/\\n/g, '\n'))
        priceRef.current?.setValue(selectedService?.price)
        hoursRef.current?.setValue(Math.floor(selectedService?.duration_minutes / 60))
        minutesRef.current?.setValue((selectedService?.duration_minutes % 60))
        // descriptionRef.current.setValue(selectedService?.Description)

        setFetchedPictures(selectedService?.images)
        setSelectedPros(selectedService?.professionals ? selectedService?.professionals : [])
        setPictures([])
        // setoriginalService({
        //     Description: descriptionRef.current.setValue(selectedService?.Description).

        // })
    }, [selectedService, proNames])



    // Image viewer stuff

    const [currentImage, setCurrentImage] = useState(0);
    const [currentDescription, setCurrentDescription] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index: number) => {
        setCurrentImage(index);
        setCurrentDescription(index)
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    // stuff to add pictures (this is mostly a duplicate of the one in "AddService")
    const [pictures, setPictures] = useState<any[]>([])
    const [compressedPictures, setCompressedPictures] = useState<any[]>([])

    const [selectedPros, setSelectedPros] = useState<any>([])

    useEffect(() => {
        // console.log(pictures)
        const compressedPicturesTemp: any[] = []

        const generateImageURL = async () => {
            pictures.map(async (pic) => {
                let blob = await fetch(pic).then(r => r.blob());
                new Compressor(blob, {
                    quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                    convertTypes: ['image/png', 'image/jpeg'],
                    convertSize: 1000000,
                    success: async (compressedResult) => {
                        compressedPicturesTemp.push(compressedResult)
                    },
                });
            })
        }
        if (pictures) {
            generateImageURL()
            // console.log("done compressing", compressedPicturesTemp)
            setCompressedPictures(compressedPicturesTemp)
        }
    }, [pictures])

    const saveChanges = async () => {
        // console.log("original service", selectedService)

        // console.log("fetched", fetchedPictures)
        // console.log(compressedPictures)

        const picturesPromises = compressedPictures.map(async (compressed, index) => {
            // console.log("URL", `businesses/${selectedShop}/services/${(categorySelector.value as string).toLowerCase()}_${(nameRef.current.getInputText() as string).toLowerCase()}_${fetchedPictures.length + index + 1}`)
            const picURL = uploadFile(compressed, `businesses/${selectedShop}/services/${((categorySelector.value as string).toLowerCase()).replace(" ", "_")}_${(nameRef.current.getInputText() as string).toLowerCase()}_${fetchedPictures.length + index + 1}`)
            if (picURL) {
                return picURL;
            } else {
                // throw new Error('Failed to upload picture');
            }
        })

        // console.log(compressedPictures)
        // await Promise.all(picturesPromises).then((url) => console.log("added", url))

        try {
            await Promise.all(picturesPromises)
                .then(async (uploadedPicsURLs) => {

                    // console.log("uploaded", [...fetchedPictures, ...uploadedPicsURLs])
                    const changes: Service = {
                        description: descriptionRef.current.getInputText(),
                        images: [...fetchedPictures, ...uploadedPicsURLs],
                        name: nameRef.current.getInputText(),
                        price: priceRef.current.getInputText(),
                        duration: `${Math.floor(parseInt(hoursRef.current.getInputText())) === 0 || Number.isNaN(parseInt(hoursRef.current.getInputText())) ? "" : `${Math.floor(parseInt(hoursRef.current.getInputText()))}h`}${parseInt(minutesRef.current.getInputText()) === 0 || Number.isNaN(parseInt(minutesRef.current.getInputText())) ? "" : `${parseInt(minutesRef.current.getInputText())}mins`}`,
                        duration_minutes: (parseInt(hoursRef.current.getInputText() ? hoursRef.current.getInputText() : 0) * 60) + parseInt(minutesRef.current.getInputText() ? minutesRef.current.getInputText() : 0),
                        professionals: selectedPros
                    }

                    // console.log("new service", changes)

                    await updateShopService({ queryKey: ["shopID", selectedShop] }, selectedService, changes, categorySelector?.value)
                        .then(() => {
                            showToast("Service updated", "success")
                        })
                        .catch(() => {
                            showToast("Couldn't update service, check your internet connection and try again", "error", 7000)
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                })


        } catch (e) {
            console.log("Couldn't update service. Error", e)
            showToast("Couldn't update service, check your internet connection and try again", "error", 7000)
        }

    }

    useEffect(() => {
        console.log(selectedPros);
    }, [selectedPros])
    
    return (
        <>

            <div className="w-full md:w-[24rem] bg-white rounded-lg p-4 h-full overflow-auto">
                <div className='flex justify-end cursor-pointer' onClick={onClose}>
                    <Icon.X />
                </div>
                <div className='w-full flex justify-center'>
                    <p className="mb-4 text-lg font-semibold">
                        Service details
                    </p>
                </div>

                {loadingDetails ?
                    <>
                        <div className='flex flex-1 grow justify-center '>
                            <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                        </div>
                    </> :
                    <>
                        {selectedService ?
                            <>
                                <div>
                                    <div className='text-lg font-medium mb-2'>
                                        Service
                                    </div>
                                    {/* <TextInput onChange={() => setMadeChanges(true)} ref={categoryRef} label='Category'></TextInput> */}
                                    <div className='text-sm font-medium'>
                                        Category
                                    </div>
                                    <p className='text-sm text-gray-600 mb-2'>
                                        Select from an existing category, or type in the name of a new category to create it.
                                    </p>
                                    {canEditService(roles) ?
                                        <>
                                            <Creatable options={categories.map((category: any) => {
                                                return {
                                                    label: category.name,
                                                    value: category.id
                                                }
                                            })} value={categorySelector} onChange={(change) => {
                                                setMadeChanges(true)
                                                // console.log(change)
                                                setCategorySelector(change)
                                            }} onCreateOption={async (newOption) => {
                                                setMadeChanges(true)

                                                const newAppointmentID = await saveCategory(selectedShop, newOption, "");

                                                setCategorySelector({
                                                    label: newOption,
                                                    value: newAppointmentID
                                                })

                                                dispatch.Shop.addCategory({
                                                    id: newAppointmentID,
                                                    name: newOption,
                                                    description: "",
                                                })

                                                dispatch.Shop.setLatestCategory({
                                                    id: newAppointmentID,
                                                    name: newOption,
                                                    description: "",
                                                })
                                            }} />
                                        </>
                                        :
                                        <>
                                            <div>
                                                {categorySelector.value}
                                            </div>
                                        </>}
                                </div>
                                <div className='mt-4'>
                                    <TextInput onChange={() => setMadeChanges(true)} ref={nameRef} label='Service name' placeholder="ie: Regular Haircut, Braids" disabled={!canEditService(roles)}></TextInput>
                                </div>
                                <div className='mt-4'>
                                    <TextInput disabled={!canEditService(roles)} area onChange={() => {
                                        setMadeChanges(true)
                                        
                                    }} ref={descriptionRef} label='Description' placeholder='Write your description here...'></TextInput>

                                </div>
                                <div className=' w-full mt-4'>
                                    <label className='text-sm font-medium mb-2'>{"Price"}</label>
                                    <p className='text-sm text-gray-600 mb-2'>
                                        Price changes will only affect new appointments. Currently booked appointments will keep the old prices.
                                    </p>
                                    <div className='flex gap-2 items-center'>
                                        <span className="text-gray-500 text-md ">{shopsData[selectedShop]?.currency}</span>
                                        <TextInput disabled={!canEditService(roles)} type={"number"} min={0} onChange={() => setMadeChanges(true)} ref={priceRef} ></TextInput>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className='text-sm font-medium mb-2'>
                                        Duration
                                    </div>
                                    <div className='flex w-full items-center justify-between'>
                                        <div className='flex items-center gap-2'>
                                            <div className='w-full'>
                                                <TextInput disabled={!canEditService(roles)} type={"number"} placeholder='Hours' min={0} inputSize='sm' onChange={() => setMadeChanges(true)} ref={hoursRef} ></TextInput>
                                            </div>
                                            <div>
                                                hour(s)
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <div>
                                                <TextInput disabled={!canEditService(roles)} type={"number"} min={0} placeholder='Minutes' inputSize='sm' onChange={() => setMadeChanges(true)} ref={minutesRef} ></TextInput>
                                            </div>
                                            <div>
                                                minutes
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className='text-lg font-medium mb-2'>Professionals</div>
                                    <div className='flex gap-3 flex-wrap'>


                                        <div className='text-xs text-gray-500'>
                                            Staff members that provide this service
                                        </div>
                                        {proNames?.length === 0 ?
                                            <>
                                                <div className='flex w-full justify-center'>
                                                    <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='flex gap-2 flex-wrap w-full'>
                                                    {proNames[selectedShop]?.map((pro: any, index: number) => {
                                                        return (
                                                            <div key={index}>
                                                                <div className={`transition px-4 py-2 border rounded-lg flex items-center justify-center text-center text-sm ${canEditService(roles) && canAssignServiceToStaff(roles) && "cursor-pointer"} border-gray-100 ${selectedPros?.includes(proIDs[selectedShop as any][index]) ? "border border-2 border-gray-900" : "bg-white"}`}
                                                                    onClick={() => {
                                                                        if (canEditService(roles) && canAssignServiceToStaff(roles)) {
                                                                            setMadeChanges(true)

                                                                            const proID = proIDs[selectedShop as any][index];

                                                                            if (!selectedPros.includes(proID)) {
                                                                                // If the professional ID is not already selected, add it to the array
                                                                                setSelectedPros([...selectedPros, proID])
                                                                                // console.log([...selectedPros, proID])
                                                                            } else {
                                                                                // If the professional ID is already selected, remove it from the array
                                                                                setSelectedPros(selectedPros.filter((id: string) => id !== proID));
                                                                                // console.log(selectedPros.filter((id: string) => id !== proID));
                                                                            }
                                                                        }
                                                                    }}>
                                                                    {pro}
                                                                </div>
                                                            </div>)
                                                    })}
                                                </div>
                                            </>}

                                    </div>

                                </div>
                                <div className='mt-4'>
                                    <div className='text-lg font-medium mb-2'>Pictures</div>
                                    <div className='flex gap-3 flex-wrap'>
                                        {/* Images fetched from DB */}
                                        {pictures.length !== 0 ?
                                            <>
                                                {fetchedPictures?.length !== 0 &&
                                                    (fetchedPictures?.map((image: any, index: number) => {
                                                        return (
                                                            <div key={index} className='relative'>
                                                                <div className='absolute flex justify-center items-center border border-gray-100 drop-shadow-sm cursor-pointer -right-2 -top-2 w-6 h-6 bg-white rounded-full'
                                                                    onClick={() => {
                                                                        setFetchedPictures(pics => pics.slice(0, index).concat(pics.slice(index + 1)))
                                                                        setMadeChanges(true)
                                                                    }}
                                                                >
                                                                    <Icon.X size={16} />
                                                                </div>
                                                                <img key={index} className='w-24 h-24 rounded-lg object-cover object-center cursor-pointer hover:border hover:border-gray-300' src={image} alt="service" onClick={() => {
                                                                    openImageViewer(index)
                                                                }}></img>
                                                            </div>
                                                        )
                                                    }))
                                                }
                                            </>
                                            :
                                            <>
                                                <div className={"text-sm text-gray-600"}>No pictures</div>
                                            </>
                                        }
                                        {/* Images they just added */}
                                        {pictures.map((image: any, index: number) => {
                                            return (
                                                <div key={index} className='relative'>
                                                    {canEditService(roles) &&
                                                        <div className='absolute flex justify-center items-center border border-gray-100 drop-shadow-sm cursor-pointer -right-2 -top-2 w-6 h-6 bg-white rounded-full'
                                                            onClick={() => {
                                                                setPictures(pics => pics.slice(0, index).concat(pics.slice(index + 1)))
                                                                setMadeChanges(true)
                                                            }}
                                                        >
                                                            <Icon.X size={16} />
                                                        </div>
                                                    }
                                                    <img key={index} className='w-24 h-24 rounded-lg object-cover object-center cursor-pointer hover:border hover:border-gray-300' src={image} alt="service" onClick={() => {
                                                        openImageViewer(selectedService?.Images.length + index)
                                                    }}></img>
                                                </div>
                                            )
                                        })}

                                        {/* Add images label */}
                                        {canEditService(roles) &&
                                            <div className='w-24 h-24 rounded-lg object-cover object-center cursor-pointer border border-gray-300 flex flex-col justify-center items-center'>
                                                <label htmlFor="dropzone-file" className='flex flex-col justify-center items-center cursor-pointer w-full h-full'>
                                                    <Icon.PlusCircle color={"#475467"} size={20} />

                                                    <div className='text-gray-600 text-sm'>
                                                        Add image
                                                    </div>

                                                </label>
                                                <input id="dropzone-file" type="file" className="hidden"
                                                    onChange={(event) => {
                                                        const pics: any[] = [];
                                                        if (event) {
                                                            if (!event.target.files) return;
                                                            Array.from(event.target.files).forEach((file) => {
                                                                pics.push(URL.createObjectURL(file))
                                                            })

                                                            // console.log(pics)
                                                            setPictures(prevPictures => [...prevPictures, ...pics])
                                                            setMadeChanges(true)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }

                                    </div>

                                </div>
                                {canEditService(roles) &&
                                    <div className='mt-4'>
                                        <Button loading={loading} disabled={!madeChanges} onClick={() => {
                                            setLoading(true)
                                            saveChanges()
                                        }} size={'sm'}>Save changes</Button>
                                    </div>
                                }
                                {canRemoveService(roles) &&
                                    <div className='mt-2'>
                                        <Button size='sm' destructive secondary disabled={false} onClick={() => {
                                            onPressDelete && onPressDelete()
                                        }}>Delete service</Button>
                                    </div>
                                }
                            </> :
                            <>
                            </>


                        }
                    </>}




            </div>

            {isViewerOpen && <div className={`transition-all opcacity-0 ${isViewerOpen && "opcacity-100"}`}>
                <ImageViewer
                    src={[...selectedService?.images, ...pictures]}
                    backgroundStyle={{ zIndex: 99999, backgroundColor: "#fff" }}
                    currentIndex={currentImage}
                    disableScroll={true}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    closeComponent={<Icon.X color='#000' />}
                    leftArrowComponent={<Icon.ArrowLeft color='#000' />}
                    rightArrowComponent={<Icon.ArrowRight color='#000' />}

                />
                {/* <p className='text-white'
                    style={{ 'zIndex': 999999 }}
                >Salut</p> */}
            </div>}
        </>
    )
}

export default ServiceDetails