import moment from 'moment';
import { useEffect, useState } from 'react'
import axios, { AxiosInstance } from 'axios';
import * as Icon from 'react-feather';
import { Oval } from 'react-loader-spinner'
import AppointmentStatusDetails from './AppointmentStatusDetails';
import { Button } from './button';
import { shopSelector } from '../store/models/shopModel';
import { useSelector } from 'react-redux';
import { professionalSelector } from '../store/models/professionalsModel';
import { showToast } from '../utils/utilities';
import { addMinutes } from 'date-fns';
import { Service } from '../types';

interface IAppointmentDetails {
    selectedBooking: any,
    activePage: any,
    isMobile: any,
    onCheckout: () => void,
    onDidntShow: () => void,
    onAccept: () => void,
    onReject: () => void,
    onActionError: () => void
}

function AppointmentDetails({ selectedBooking, activePage, isMobile, onCheckout, onDidntShow, onAccept, onReject, onActionError }: IAppointmentDetails) {
    let client: AxiosInstance;

    if (process.env.NODE_ENV === "development") {
        client = axios.create({
            baseURL: "http://127.0.0.1:5001/barbapp-d4159/us-central1/"
        })
    } else {
        client = axios.create({
            baseURL: "https://us-central1-barbapp-d4159.cloudfunctions.net/"
        })
    }

    const [appointmentActionLoading, setAppointmentActionLoading] = useState(false)
    const [checkingOut, setCheckingOut] = useState<boolean>(false)
    const [checkoutCondition1, setcheckoutCondition1] = useState<boolean>(false)
    const [checkoutCondition2, setcheckoutCondition2] = useState<boolean>(false)
    const [checkoutAmount, setCheckoutAmount] = useState<any>(null)

    // const { selectedPro, selectedProIndex } = useContext<any>(DatabaseStreamContext)
    const { selectedPro, selectedProIndex } = useSelector(professionalSelector)
    const { selectedShop, shopsData } = useSelector(shopSelector)
    const { proNames } = useSelector(professionalSelector)

    useEffect(() => {
        setCheckingOut(false)
        setcheckoutCondition1(false)
        setcheckoutCondition2(false)
    }, [selectedBooking, selectedPro])

    return (
        <>
            {!checkingOut ?
                <>
                    <div className="">
                        <div className='w-full flex justify-center'>
                            <p className="mb-4 text-lg font-semibold">
                                Appointment details
                            </p>
                        </div>
                        {selectedBooking ?
                            <>
                                <div className='flex items-center justify-between'>
                                    <div className="">
                                        <p className="text-lg font-bold">
                                            {moment(selectedBooking?.isoDate).format(
                                                'dddd'
                                            )}
                                        </p>
                                        <p className="font-normal text-base text-[#343434]">
                                            {
                                                //show the date
                                                `${moment(selectedBooking?.isoDate).format(
                                                    'MMMM Do'
                                                )}, 
                            ${moment(selectedBooking?.isoDate).format(
                                                    'LT'
                                                )} - ${moment(selectedBooking?.endTime).format(
                                                    'LT'
                                                )}`
                                                //then the time
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className='text-[#343434] mt-4'>
                                    {`Type: ${selectedBooking.appointmentType ? selectedBooking.appointmentType.charAt(0).toUpperCase() + selectedBooking.appointmentType.slice(1) : "Appointment"}`}
                                </div>

                                <div className="flex justify-between bg-gray-50 p-4 rounded-lg my-5">
                                    <div className="flex items-center gap-4">
                                        {/* <img
                                            className="object-cover w-16 h-16 rounded-full"
                                            src={
                                                selectedBooking?.user
                                                    ?.Client_Profile_Image
                                            }
                                            alt="Client"
                                        /> */}
                                        <div>
                                            <p className='text-md font-semibold'>
                                                {selectedBooking?.user?.name ||
                                                    '[None]'}
                                            </p>
                                            {isMobile ? <>

                                                <div className='flex gap-2'>
                                                    <div className='my-auto'>
                                                        <Icon.Phone size={14} color="#667085" />
                                                    </div>
                                                    <p className='text-sm text-gray-500'>
                                                        {selectedBooking?.user?.phone}
                                                    </p>
                                                </div>



                                                <div className='flex gap-2'>
                                                    <div className='my-auto'>
                                                        <Icon.Mail size={14} color="#667085" />
                                                    </div>
                                                    <p className='text-sm text-gray-500'>
                                                        {selectedBooking?.user?.email}
                                                    </p>
                                                </div>

                                            </> :
                                                <>
                                                    <a href={`tel:${selectedBooking?.user?.phone}`}>
                                                        <div className='flex gap-2'>
                                                            <div className='my-auto'>
                                                                <Icon.Phone size={14} color="#667085" />
                                                            </div>
                                                            <p className='text-sm text-gray-500'>
                                                                {selectedBooking?.user?.phone}
                                                            </p>
                                                        </div>
                                                    </a>

                                                    <a
                                                        href={`mailto:${selectedBooking?.user?.email}`}
                                                    >
                                                        <div className='flex gap-2'>
                                                            <div className='my-auto'>
                                                                <Icon.Mail size={14} color="#667085" />
                                                            </div>
                                                            <p className='text-sm text-gray-500'>
                                                                {selectedBooking?.user?.email}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </>}

                                        </div>

                                    </div>

                                </div>
                                {isMobile && (
                                    <div className="flex justify-between gap-2">
                                        <a href={`tel:${selectedBooking?.user?.phone}`}
                                            className='flex justify-center items-center py-2 bg-gray-50 border flex-1 rounded lg gap-2'>
                                            <div className='flex gap-2 justify-center items-center'>
                                                <Icon.Phone size={18} />
                                                <p>Call</p>
                                            </div>
                                        </a>

                                        <a
                                            href={`mailto:${selectedBooking?.user?.email}`}
                                            className='flex justify-center items-center py-2 bg-gray-50 border flex-1 rounded lg gap-2'
                                        >
                                            <div className='flex gap-2 justify-center items-center'>

                                                <Icon.Mail size={18} />
                                                <p>Send email</p>
                                            </div>
                                        </a>

                                    </div>
                                )}
                                <div className='px-8'>
                                    {selectedBooking?.services?.map(
                                        (service: Service, index: number) => {
                                            return (
                                                <div key={index}>
                                                    <div className="flex justify-between mt-2">
                                                        <span className="block text-md">
                                                            {service?.name}
                                                        </span>
                                                        <div>
                                                            <span className="mr-1 text-[10px] uppercase">
                                                                {shopsData[selectedShop]?.currency}
                                                            </span>
                                                            <span className="text-md">
                                                                {parseFloat(
                                                                    service?.price.toString()
                                                                ).toFixed(2)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="flex justify-between mt-2 text-zinc-500 mt-4">
                                            <span className="block text-md">
                                                {'Wavier fees'}
                                            </span>
                                            <div>
                                                <span className="mr-1 text-[10px] uppercase">
                                                    GHS
                                                </span>
                                                <span className="text-md">
                                                    {(
                                                        parseFloat(
                                                            service.Price
                                                        ) * 0.0
                                                    ).toFixed(2)}
                                                </span>
                                            </div>
                                        </div> */}
                                                </div>
                                            )
                                        }
                                    )}

                                    {/* dahsed divdider */}
                                    <div className="divide-y divide-dashed my-6">
                                        <div></div>
                                        <div></div>
                                    </div>

                                    <div className="w-full flex justify-end items-center">
                                        {/* <div className="flex items-center justify-center gap-2 bg-[#F9F9F9] rounded-lg h-[40px] px-2">
                                 
                                            <span className="text-xs font-normal text-gray-500">
                                                To be paid after appointment
                                            </span>
                                        </div> */}

                                        <div className="flex items-center justify-center justify-between">
                                            <div>
                                                <span className="mr-2 text-xs font-normal">
                                                    Total
                                                </span>
                                                <span className="mr-1 text-[10px] font-bold uppercase">
                                                    {shopsData && shopsData[selectedShop]?.currency}
                                                </span>
                                                <span className="text-base font-bold">
                                                    {parseFloat(
                                                        selectedBooking?.services?.reduce(
                                                            (
                                                                a: { price: any },
                                                                b: { price: any }
                                                            ) => {
                                                                // setCheckoutAmount((parseFloat((a).toString()) + parseFloat(b?.Price) * 100).toFixed(0))
                                                                return parseFloat((a).toString()) + parseFloat(b?.price)
                                                            },
                                                            0
                                                        )
                                                    ).toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className=" px-5 py-3 my-6 bg-[#F9F9F9] rounded-2xl">
                                    <span className="inline-block text-xs font-normal">
                                        Additional notes:
                                    </span>
                                    {selectedBooking?.additionalNotes?.length ? (
                                        <p className="text-sm font-normal">
                                            {selectedBooking?.additionalNotes}
                                        </p>
                                    ) : (
                                        <p className="text-sm italic font-normal text-zinc-500">
                                            No additional notes from the client
                                        </p>
                                    )}
                                </div>

                                {activePage === 'upcoming' ?
                                    <>
                                        {!appointmentActionLoading ?
                                            <>
                                                {selectedBooking?.status === "pending" ?
                                                    <>
                                                        <div className="flex gap-6 px-4 py-2 mx-auto justify-evenly rounded-2xl w-fit">
                                                            <button className="bg-green-500 w-32 text-white px-4 py-2.5 text-xs font-bold rounded-lg border" onClick={() => {
                                                                console.log({
                                                                    clientID: selectedBooking.clientID,
                                                                    businessID: selectedBooking.businessID,
                                                                    appointmentID: selectedBooking.appointmentID,
                                                                })

                                                                client.post("/acceptAppointment", {
                                                                    clientID: selectedBooking.clientID,
                                                                    businessID: selectedBooking.businessID,
                                                                    appointmentID: selectedBooking.appointmentID,
                                                                }).then((response) => {
                                                                    console.log("Could accept.", response)
                                                                    setAppointmentActionLoading(false)
                                                                    onAccept()
                                                                }).catch((e) => {
                                                                    console.log("Couldn't accept.", e)
                                                                    setAppointmentActionLoading(false)
                                                                    showToast("We couldn't accept the appointment, check your internet connection and try again", "error", 7000)
                                                                });
                                                                setAppointmentActionLoading(true)
                                                            }}>
                                                                Accept
                                                            </button>

                                                            <button className="bg-[#F9F9F9] w-32 px-4 py-2.5 text-xs font-bold rounded-lg border" onClick={() => {
                                                                client.post("/rejectAppointment", {
                                                                    clientID: selectedBooking.clientID,
                                                                    businessID: selectedBooking.businessID,
                                                                    appointmentID: selectedBooking.appointmentID,
                                                                }).then((response) => {
                                                                    console.log("Could reject.", response)
                                                                    setAppointmentActionLoading(false)
                                                                    onReject()
                                                                }).catch((e) => {
                                                                    console.log("Couldn't reject", e)
                                                                    setAppointmentActionLoading(false)
                                                                    showToast("We couldn't reject the appointment, check your internet connection and try again", "error", 7000)
                                                                });
                                                                setAppointmentActionLoading(true)
                                                            }}>
                                                                Reject
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {shopsData &&
                                                            <div className="flex w-full gap-4 px-4 py-2  justify-evenly rounded-2xl">

                                                                <Button disabled={false} onClick={() => {
                                                                    if (process.env.NODE_ENV === "development") {
                                                                        setCheckingOut(true)
                                                                    } else {
                                                                        if (!moment().isSameOrAfter(addMinutes(new Date(selectedBooking.endTime), -10))) {
                                                                            showToast(`You can only checkout at the end of this appointment (expected at ${moment(new Date(selectedBooking.endTime)).format("LT")})`, "error", 7000)
                                                                        } else {
                                                                            setCheckingOut(true)
                                                                        }
                                                                    }

                                                                    //uncomment this and comment above to checkout at will
                                                                    // setCheckingOut(true)
                                                                }} size="sm" >Checkout</Button>

                                                                <Button disabled={false} onClick={() => {
                                                                    if (process.env.NODE_ENV === "development") {
                                                                        client.post("/didntShowToAppointment", {
                                                                            clientID: selectedBooking.clientID,
                                                                            businessID: selectedBooking.businessID,
                                                                            appointmentID: selectedBooking.appointmentID,
                                                                        }).then((response) => {
                                                                            console.log("Could didn't show.", response)
                                                                            setAppointmentActionLoading(false)
                                                                            onDidntShow()
                                                                        }).catch((e) => {
                                                                            console.log("Could'nt didn't show.", e)
                                                                            setAppointmentActionLoading(false)
                                                                            showToast("We couldn't set the appointment as Didn't show, check your internet connection and try again", "error", 7000)
                                                                        });
                                                                        setAppointmentActionLoading(true)
                                                                    } else {

                                                                        if (!moment().isSameOrAfter(addMinutes(new Date(selectedBooking.isoDate), shopsData[selectedShop].allowedLateness))) {
                                                                            showToast(`You can only mark this appointment as didn't show at ${moment(addMinutes(new Date(selectedBooking.isoDate), shopsData[selectedShop].allowedLateness)).format("LT")}, ${shopsData[selectedShop].allowedLateness} minutes after the appointment's start time`, "error", 7000)
                                                                        } else {
                                                                            client.post("/didntShowToAppointment", {
                                                                                clientID: selectedBooking.clientID,
                                                                                businessID: selectedBooking.businessID,
                                                                                appointmentID: selectedBooking.appointmentID,
                                                                            }).then((response) => {
                                                                                console.log("Could didn't show.", response)
                                                                                setAppointmentActionLoading(false)
                                                                                onDidntShow()
                                                                            }).catch((e) => {
                                                                                console.log("Could'nt didn't show.", e)
                                                                                setAppointmentActionLoading(false)
                                                                                showToast("We couldn't set the appointment as Didn't show, check your internet connection and try again", "error", 7000)
                                                                            });
                                                                            setAppointmentActionLoading(true)
                                                                        }
                                                                    }
                                                                }} size="sm" secondaryGray>Didn't show</Button>
                                                            </div>}
                                                    </>}
                                            </> :
                                            <>
                                                <div className='flex w-full justify-center'>
                                                    <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                                                </div>
                                            </>}
                                    </> :
                                    <>
                                        <AppointmentStatusDetails status={selectedBooking?.status} />
                                    </>}
                            </>
                            :
                            <>
                                <div className="flex flex-col w-full my-5 items-center justify-center">
                                    <p className='text-md font-semibold '>No appointment selected</p>
                                    <p className='text-sm text-gray-500'>Select an appointment and the details will appear here</p>
                                </div>
                            </>}



                    </div>
                </>
                :
                <>
                    <div className='flex-1 flex justify-center items-center flex-col w-full h-full'>
                        <div className='mb-2 text-lg font-semibold'>
                            Complete appointment and checkout ?
                        </div>
                        <div className='mb-4 w-full flex text-center'>
                            Please confirm that you have successfully completed the service before proceeding to checking out.
                        </div>

                        <div className={`w-full flex justify-start items-center border py-4 rounded-lg px-2 gap-4 cursor-pointer transition ${checkoutCondition1 ? "border-gray-900" : "border-gray-300"}`}
                            onClick={() => {
                                setcheckoutCondition1(!checkoutCondition1)
                            }}>
                            <input readOnly className='transition-all accent-gray-900' type={"checkbox"} onClick={() => {
                                setcheckoutCondition1(!checkoutCondition1)
                            }} checked={checkoutCondition1}></input>
                            <div>
                                Yes, the client showed up for the appointment
                            </div>
                        </div>
                        <div className={`transition w-full flex justify-start items-center border py-4 rounded-lg px-2 gap-4 cursor-pointer mt-2 ${checkoutCondition2 ? "border-gray-900" : "border-gray-300"}`}
                            onClick={() => {
                                setcheckoutCondition2(!checkoutCondition2)
                            }}>
                            <input readOnly className='accent-gray-900' type={"checkbox"} onClick={() => {
                                setcheckoutCondition1(!checkoutCondition2)
                            }} checked={checkoutCondition2}></input>
                            <div>
                                Yes, I completed the service(s) booked by the client
                            </div>
                        </div>

                        <div className='mt-4 w-full'>
                            <Button loading={appointmentActionLoading} disabled={!(checkoutCondition1 && checkoutCondition2)} onClick={() => {
                                // console.log(proNames[selectedShop][selectedProIndex])
                                client.post("/completeAppointment", {
                                    clientID: selectedBooking.clientID,
                                    businessID: selectedBooking.businessID,
                                    appointmentID: selectedBooking.appointmentID,
                                    reason: `Wavier checkout for service to client ${selectedBooking?.user?.name} by pro ${proNames[selectedShop][selectedProIndex]}`,
                                    amount: (parseFloat(
                                        selectedBooking?.services?.reduce(
                                            (
                                                a: { Price: any },
                                                b: { Price: any }
                                            ) => {
                                                return parseFloat((a).toString()) + parseFloat(b?.Price)
                                            },
                                            0
                                        )
                                    ) * 100).toFixed(0),
                                    recipientID: shopsData[selectedShop].recipientID
                                }).then((response) => {
                                    console.log("Could checkout.", response)
                                    setAppointmentActionLoading(false)
                                    onCheckout()
                                }).catch((e) => {
                                    console.log("Could'nt checkout.", e)
                                    setAppointmentActionLoading(false)
                                    showToast("We couldn't checkout for now, check your internet connection and try again", "error", 7000)
                                });
                                setAppointmentActionLoading(true)
                            }}
                                size={'sm'}>
                                {"Complete & checkout"}
                            </Button>
                        </div>

                        <div className='mt-2 w-full'>
                            <Button disabled={false} onClick={() => {
                                setCheckingOut(false)
                                setcheckoutCondition1(false)
                                setcheckoutCondition2(false)
                            }}
                                secondaryGray
                                size={'sm'}>
                                {"Cancel"}
                            </Button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default AppointmentDetails