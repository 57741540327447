import React, { useState } from 'react'
import * as Icon from "react-feather"
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux'
import { shopSelector } from '../store/models/shopModel'
import { useNavigate } from 'react-router-dom'
import { Button } from './button'
import { auth } from '../config/firebase.config'
import { userSelector } from '../store/models/userModel'
import { analyticsEvents } from '../utils/Events'
import { usePostHog } from 'posthog-js/react'
import UpgradeButton from './UpgradeButton';
import { differenceInCalendarDays } from 'date-fns';

enum PageParts {
    SIDEBAR = 'sidebar',
    BOOKINGS = 'bookings',
    APPOINTMENT_DETAILS = 'appointments',
}

interface IHeader {
    pageParts: string,
    onCloseMenu: () => void,
    onOpenMenu: () => void,
    isMobile?: boolean,
}



function Header({
    pageParts,
    onCloseMenu,
    onOpenMenu,
    isMobile
}: IHeader) {
    const posthog = usePostHog()
    const [referenceElement, setReferenceElement] = useState<any>();
    const [popperElement, setPopperElement] = useState<any>()
    const [open, setOpen] = useState(false)
    const [openTasks, setOpenTasks] = useState(false)

    let { styles, attributes } = usePopper(referenceElement, popperElement)

    const { shopsData, selectedShop } = useSelector(shopSelector)
    const { email } = useSelector(userSelector)

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const logout = () => {
        auth.signOut()
        dispatch.User.saveUser({})
        navigate("/signin");
        analyticsEvents.logout();
        posthog.capture("Logout")
        // window.location.pathname = "/signin"

        window.location.reload();
    }

    const { subscription } = useSelector(userSelector);

    return (
        <header
            className={`sticky top-0 z-30 flex items-center py-2 bg-white ${pageParts !== PageParts.SIDEBAR && 'border-b'
                }  border-gray-100`}
        >
            <div className='w-full  flex justify-between xl:justify-end px-4'>
                <div className="pt-1 xl:hidden">
                    {((pageParts === PageParts.APPOINTMENT_DETAILS && isMobile) ||
                        pageParts === PageParts.SIDEBAR) && (
                            <div
                                onClick={onCloseMenu}

                            >
                                {/* <MenuCloseIcon /> */}
                                <Icon.X />
                            </div>
                        )}

                    {(pageParts === PageParts.BOOKINGS || (pageParts === PageParts.APPOINTMENT_DETAILS && !isMobile)) && (
                        <div>
                            <div
                                onClick={onOpenMenu}
                            >
                                {/* <MenuIcon /> */}
                                <Icon.Menu />
                            </div>
                        </div>
                    )}
                </div>
                <div className='font-pacifico flex justify-center items-center w-full text-2xl xl:hidden'>
                    Wavier
                </div>



                <div className='flex gap-4 items-center'>

                    {shopsData[selectedShop].settings && (shopsData[selectedShop].settings?.subscription?.billingState === "trialing")&&
                        <div onClick={() => {
                            setOpenTasks(open => !open)
                            setOpen(false)
                        }} className=" px-4 py-2 hidden rounded-lg border border-gray-200 justify-end items-center gap-2 inline-flex">

                            {differenceInCalendarDays(new Date(shopsData[selectedShop].settings?.subscription?.trialEnd), new Date(Date.now()))} day(s) left in trial
                        </div>
                    }

                    {shopsData[selectedShop].settings && (shopsData[selectedShop].settings?.subscription?.billingState === "inactive")&&
                        <div onClick={() => {
                            setOpenTasks(open => !open)
                            setOpen(false)
                        }} className=" px-4 py-2 cursor-pointer hidden rounded-lg border border-gray-200 justify-end items-center gap-2 xl:inline-flex">

                            <UpgradeButton color='purple' />
                        </div>
                    }

                    <div ref={setReferenceElement} onClick={() => {
                        setOpen(open => !open)
                        setOpenTasks(false)
                    }} className='p-2 bg-gray-200 rounded-full cursor-pointer'>
                        {!open ?
                            <div>
                                <Icon.User size={16} />
                            </div>
                            :
                            <div>
                                <Icon.X size={16} />
                            </div>}
                    </div>
                </div>

                {open &&
                    <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className='overflow-clip my-2 rounded-lg pb-2 shadow-md border-2 bg-white'>
                        <div className="px-4 py-4 bg-gray-50 border-b-2">
                            <div className="text-sm text-gray-700">
                                Signed in as
                            </div>
                            <div className="font-semibold">
                                {email}
                            </div>
                        </div>


                        <>
                            {subscription &&
                                <>
                                    {/* <div className='px-4 py-2 '>
                                        {`Currently on the ${subscription.plan === "free" ? "Free" : "Pro"} plan`}
                                    </div> */}
                                    <div className='px-4 pt-4'>
                                        <Button secondaryGray disabled={false} onClick={() => {
                                            setOpen(false)
                                            dispatch.Modals.toggleUpgradeModal()
                                            console.log("Show modal")
                                            analyticsEvents.attemptUpgrade("upgrade_header")
                                            posthog.capture("Attempt upgrade", {
                                                Location: "Header"
                                            })
                                        }}
                                            size={'sm'}>{`${subscription.plan === "free" ? "Free plan 🙃" : "Pro plan 🚀"} `}</Button>
                                    </div>

                                </>
                            }

                            {shopsData[selectedShop].settings?.subscription?.billingState === "trialing" &&
                                <div onClick={() => {
                                    setOpenTasks(open => !open)
                                    setOpen(false)
                                }} className=" px-4 py-2  m-2  xl:hidden rounded-lg border border-gray-200 justify-center items-center gap-2 flex">

                                    {differenceInCalendarDays(new Date(shopsData[selectedShop].settings?.subscription?.trialEnd), new Date(Date.now()))} day(s) left in trial
                                </div>
                            }

                            {shopsData[selectedShop].settings?.subscription?.billingState === "inactive" &&
                                <div onClick={() => {
                                    setOpenTasks(open => !open)
                                    setOpen(false)
                                }} className=" px-4 py-2 cursor-pointer m-2 mr-2 xl:hidden rounded-lg border border-gray-200 justify-center items-center gap-2 flex">

                                    <UpgradeButton color='purple' />
                                </div>
                            }


                            <div className='hover:bg-gray-50 px-4 py-2 cursor-pointer flex gap-2 items-center mt-2'
                                onClick={() => {
                                    setOpen(false)
                                    navigate("settings/profile")
                                }}
                            >
                                <div>
                                    <Icon.Settings size={16} />
                                </div>
                                <div>
                                    Settings
                                </div>
                            </div>
                            <div className='hover:bg-gray-50 px-4 py-2 cursor-pointer flex gap-2 items-center mt-2'
                                onClick={() => {
                                    setOpen(false)
                                    navigate("settings/preferences")
                                }}
                            >
                                <div>
                                    <Icon.Sliders size={16} />
                                </div>
                                <div>
                                    Preferences
                                </div>
                            </div>
                            <div className='hover:bg-gray-50 px-4 py-2 cursor-pointer flex gap-2 items-center mt-2'
                                onClick={() => {
                                    setOpen(false)
                                    navigate("settings/plans")
                                }}
                            >
                                <div>
                                    <Icon.Box size={16} />
                                </div>
                                <div>
                                    Manage plans
                                </div>
                            </div>
                            <a href='https://wavier.canny.io/feature-requests' target="_blank" rel="noreferrer">
                                <div className='hover:bg-gray-50 px-4 py-2 cursor-pointer flex gap-2 items-center mt-2'

                                >
                                    <div>
                                        <Icon.PlusSquare size={16} />
                                    </div>
                                    <div>
                                        Request a feature
                                    </div>
                                </div>
                            </a>
                        </>


                        <div className='hover:bg-gray-50 px-4 py-2 cursor-pointer flex gap-2 items-center'
                            onClick={() => {
                                logout()
                            }}
                        >
                            <div>
                                <Icon.LogOut color='#f04438' size={16} />
                            </div>
                            <div className='text-error-500'>
                                Sign out
                            </div>
                        </div>
                    </div>
                }


                {openTasks &&
                    <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="w-[400px] opacity-0 transition-all mt-4 p-0.5 bg-white rounded-lg shadow border-2 border-gray-200 flex-col justify-start items-center gap-2 inline-flex">
                        <div className="h-[102px] px-4 pt-4 pb-[18px] bg-gray-50 border-b-2 border-gray-200 flex-col justify-start items-start flex">
                            <div className="self-stretch h-5 pr-[75.75px] flex-col justify-start items-start flex">
                                <div className="text-slate-700 text-sm font-normal font-['Outfit'] leading-tight">Onboarding</div>
                            </div>
                            <div className="self-stretch h-12 pr-[16.75px] flex-col justify-start items-start flex">
                                <div className="self-stretch text-black text-base font-normal font-['Outfit'] leading-normal">Make the most of Wavier with these quick setup tasks and unlock your booking link.</div>
                            </div>
                        </div>
                        <div className="text-emerald-500 text-base font-normal font-['Outfit'] leading-normal">Completed 4 out of 7</div>
                        <div className="self-stretch pl-4 pr-[84.75px] py-2 justify-start items-center gap-2 inline-flex cursor-pointer hover:bg-gray-100 w-full">
                            <div className="justify-center items-center flex">
                                <input type='checkbox' />
                            </div>
                            <div className="flex-col cursor-pointer hover:bg-gray-100 w-full justify-start items-start inline-flex">
                                <div className="text-black text-base font-normal font-['Outfit'] leading-normal">Create Your Account</div>
                            </div>
                        </div>
                        <div className="self-stretch pl-4 pr-[55.75px] py-2 justify-start items-center gap-2 inline-flex">
                            <div className="justify-center items-center flex">
                                <div className="w-4 h-4 p-0.5 bg-neutral-400 rounded border border-neutral-700 justify-center items-center flex">
                                    <div className="w-3 h-3 relative flex-col justify-start items-start flex" />
                                </div>
                            </div>
                            <div className="flex-col justify-start items-start inline-flex">
                                <div className="text-black text-base font-normal font-['Outfit'] leading-normal">Business setup</div>
                            </div>
                        </div>
                        <div className="self-stretch pl-4 pr-[41.75px] py-2 justify-start items-center gap-2 inline-flex">
                            <div className="justify-center items-center flex">
                                <div className="w-4 h-4 p-0.5 bg-neutral-400 rounded border border-neutral-700 justify-center items-center flex">
                                    <div className="w-3 h-3 relative flex-col justify-start items-start flex" />
                                </div>
                            </div>
                            <div className="flex-col justify-start items-start inline-flex">
                                <div className="text-black text-base font-normal font-['Outfit'] leading-normal">Add Professionals</div>
                            </div>
                        </div>
                        <div className="self-stretch justify-start items-start inline-flex">
                            <div className="px-4 py-2 justify-start items-center gap-2 flex">
                                <div className="justify-center items-center flex">
                                    <div className="w-4 h-4 p-0.5 bg-neutral-400 rounded border border-neutral-700 justify-center items-center flex">
                                        <div className="w-3 h-3 relative flex-col justify-start items-start flex" />
                                    </div>
                                </div>
                                <div className="flex-col justify-start items-start inline-flex">
                                    <div className="text-black text-base font-normal font-['Outfit'] leading-normal">Define Your Services</div>
                                </div>
                            </div>
                        </div>
                        <div className="self-stretch justify-start items-start inline-flex">
                            <div className="px-4 py-2 justify-start items-center gap-2 flex">
                                <div className="justify-center items-center flex">
                                    <div className="w-4 h-4 relative bg-white rounded border border-gray-300" />
                                </div>
                                <div className="flex-col justify-start items-start inline-flex">
                                    <div className="text-black text-base font-normal font-['Outfit'] leading-normal">Personalize your profile</div>
                                </div>
                            </div>
                        </div>
                        <div className="self-stretch justify-start items-start inline-flex">
                            <div className="px-4 py-2 justify-start items-center gap-2 flex">
                                <div className="justify-center items-center flex">
                                    <div className="w-4 h-4 relative bg-white rounded border border-gray-300" />
                                </div>
                                <div className="flex-col justify-start items-start inline-flex">
                                    <div className="text-black text-base font-normal font-['Outfit'] leading-normal">Set up preferences</div>
                                </div>
                            </div>
                        </div>
                        <div className="self-stretch justify-start items-start inline-flex">
                            <div className="px-4 py-2 justify-start items-center gap-2 flex">
                                <div className="justify-center items-center flex">
                                    <div className="w-4 h-4 relative bg-white rounded border border-gray-300" />
                                </div>
                                <div className="flex-col justify-start items-start inline-flex">
                                    <div className="text-black text-base font-normal font-['Outfit'] leading-normal">Share your Booking Portal</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </header>
    )
}

export default Header