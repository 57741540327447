import axios, { AxiosInstance } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import * as Icon from "react-feather"
import { useDispatch, useSelector } from 'react-redux'
import { modalSelector } from '../../store/models/modals'
import { userSelector } from '../../store/models/userModel'
import { analyticsEvents } from '../../utils/Events'
import { Button } from '../button'
import { usePostHog } from 'posthog-js/react'
import { shopSelector } from '../../store/models/shopModel'
import { fetchCategories, saveCategory } from '../../api/api'
import TextInput from '../input/TextInput'
import { showToast } from '../../utils/utilities'
import { add, setHours, setMinutes } from 'date-fns'
import Select from 'react-select'
import { DatePicker } from '../tremor/DatePicker'
import { professionalSelector } from '../../store/models/professionalsModel'
import { serviceSelector } from '../../store/models/Services'
import hours from "../../utils/day_hours.json"
import { Tooltip } from 'react-tooltip'


var hoursArray: any[] = []

function AddAppointment() {

    let client: AxiosInstance;

    if (process.env.NODE_ENV === "development") {
        client = axios.create({
            baseURL: "http://127.0.0.1:5001/barbapp-d4159/us-central1/"
        })
    } else {
        client = axios.create({
            baseURL: "https://us-central1-barbapp-d4159.cloudfunctions.net/"
        })
    }


    const { selectedShop } = useSelector(shopSelector)

    const { usersData } = useSelector(professionalSelector)
    const services = useSelector(serviceSelector)
    const posthog = usePostHog()



    const { addAppointmentModal } = useSelector(modalSelector)

    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState<any[]>([]);

    const dispatch = useDispatch();

    const [time, setTime] = useState<{ label: string, value: string } | null>(null)

    const [staff, setStaff] = useState<{ label: string, value: string } | null>(null)
    const [allStaff, setAllStaff] = useState<{ label: string, value: string }[] | null>(null)

    const [alreadyComplete, setAlreadyComplete] = useState(false)

    useEffect(() => {
        const staffData: { label: string, value: string }[] = []

        Object.keys(usersData[selectedShop]).map((key) => {
            // console.log("User data: ", usersData[selectedShop][key])
            staffData.push({
                label: usersData[selectedShop][key].name,
                value: usersData[selectedShop][key].id
            })
        })

        setAllStaff(staffData);
    }, [usersData, selectedShop])



    useEffect(() => {
        hoursArray = []
        hours.map((hours) => {
            hoursArray.push({
                label: `${hours.standard_format} ${hours.time_of_day}`,
                value: hours.military_format
            })
        })


    }, [])



    const [service, setService] = useState<{ label: string, value: string } | null>(null)
    const [allServices, setAllServices] = useState<{ label: string, value: string }[]>(null)

    useEffect(() => {
        const serviceData: { label: string, value: string }[] = []
        Object.keys(services).map((key) => {


            services[key].map((service: any) => {
                serviceData.push({
                    label: service.name,
                    value: service.id
                })
            })

        })

        setAllServices(serviceData);
    }, [services, selectedShop])

    const [aptType, setAptType] = useState<{ label: string, value: string }>({
        label: "Appointment",
        value: "appointment"
    })
    const [date, setDate] = useState<Date>(new Date());

    useEffect(() => {
        console.log("Date changed: ", date)
    }, [date])

    const toggleModal = () => {
        dispatch.Modals.toggleAddAppointmentModal(!addAppointmentModal);
    }

    const resetFields = () => {
        setStaff(null)
        setService(null)
        setAptType({
            label: "Appointment",
            value: "appointment"
        })
        setDate(new Date())
        setTime(null);
        nameRef.current.setValue("");
        emailRef.current.setValue("");
        phoneRef.current.setValue("");
    }

    const nameRef = useRef<any>()
    const phoneRef = useRef<any>()
    const emailRef = useRef<any>()
    return (
        <>
            {addAppointmentModal &&
                <>
                    <div className="absolute w-full h-screen opacity-50 z-40 bg-gray-900  flex justify-center items-center">

                    </div>
                    <div className="absolute w-full h-screen z-50 backdrop-blur-sm flex justify-center items-center">
                        <Tooltip anchorSelect=".help-already-complete" place="top" className='z-50'>
                            Check this if the appointment has already been completed. No notifications will be sent to the client.
                        </Tooltip>
                        <div className='gap-2 p-6 w-full max-w-lg flex flex-col justify-start bg-white rounded-lg mx-4 border-2 border-gray-200'>
                            <div className='w-full flex justify-end'>
                                <div className="cursor-pointer"
                                    onClick={toggleModal}
                                >
                                    <Icon.X color='#98a2b3' size={16} />
                                </div>
                            </div>
                            <div className='font-semibold text-xl'>
                                Add an appointment
                            </div>

                            <div className='w-full space-y-4'>

                                <div className="w-full space-y-2">
                                    <div className='text-sm font-medium'>
                                        Staff
                                    </div>
                                    <Select options={allStaff} isSearchable isClearable onChange={(change) => {
                                        setStaff(change)
                                        console.log(change)
                                    }}
                                        value={staff}
                                    />

                                </div>

                                <div className="w-full space-y-2">
                                    <div className='text-sm font-medium'>
                                        Service
                                    </div>
                                    <Select isMulti isClearable isSearchable options={allServices} onChange={(change) => {
                                        setService(change)
                                        console.log(change)
                                    }}
                                        value={service}
                                    />

                                </div>

                                <div className="w-full space-y-2">
                                    <div className='text-sm font-medium'>
                                        Customer
                                    </div>
                                    {/* <Select options={[
                                        {
                                            label: "Test",
                                            value: "Test",
                                        }
                                    ]} onChange={(change) => {
                                        setStaff(change)
                                        console.log(change)
                                    }}
                                        value={staff}
                                    /> */}

                                    <div className={`mb-2`}>

                                        <TextInput
                                            placeholder='Full name'
                                            onChange={() => {
                                                // setReset(false)
                                                // setUserNotFound(false)
                                            }} ref={nameRef} />


                                    </div>
                                    <div className={`mb-2`}>

                                        <TextInput
                                            placeholder='Phone number'
                                            onChange={() => {
                                                // setReset(false)
                                                // setUserNotFound(false)
                                            }} ref={phoneRef} />


                                    </div>
                                    <div className={`mb-2`}>

                                        <TextInput
                                            placeholder='Email address'
                                            onChange={() => {
                                                // setReset(false)
                                                // setUserNotFound(false)
                                            }} ref={emailRef} />


                                    </div>

                                </div>

                                <div className="w-full space-y-2">
                                    <div className='text-sm font-medium'>
                                        Appointment type
                                    </div>
                                    <Select options={[
                                        {
                                            label: "Walk-in",
                                            value: "walk-in",
                                        },
                                        {
                                            label: "Appointment",
                                            value: "appointment",
                                        },
                                    ]} onChange={(change) => {
                                        setAptType(change)
                                        console.log(change)
                                    }}
                                        value={aptType}
                                    />

                                </div>

                                <div className="w-full space-y-2">
                                    <div className='text-sm font-medium'>
                                        Appointment date
                                    </div>
                                    <DatePicker
                                        enableYearNavigation
                                        value={date}
                                        onChange={setDate}
                                        className="w-full"
                                    />

                                </div>

                                <div className="w-full space-y-2">
                                    <div className='text-sm font-medium'>
                                        Time
                                    </div>
                                    <Select isClearable menuPlacement='top' options={hoursArray} onChange={(change) => {
                                        setTime(change)
                                        console.log(change)
                                    }}
                                        value={time}
                                    />

                                </div>

                                <div className='flex flex-row items-center'>
                                    <span className='mr-2'>
                                        <input type='checkbox' onChange={(change) => {
                                            if (change.target.checked) {
                                                setAlreadyComplete(true);
                                            } else {
                                                setAlreadyComplete(false)
                                            }

                                        }}></input>
                                    </span>
                                    Appointment is already completed
                                    <span className='ml-2 help-already-complete'><Icon.Info size={16} color='#667085' /></span>

                                </div>

                            </div>
                            <div className='flex flex-col gap-2 mt-2'>

                                <Button disabled={false} loading={loading} onClick={async () => {

                                    if (!nameRef.current.getInputText() || !emailRef.current.getInputText() || !phoneRef.current.getInputText() || !staff || !service || !time) {
                                        showToast("Please fill in all required fields", "error");
                                        setLoading(false);
                                        return;
                                    }

                                    try {

                                        setLoading(true);
                                        const serviceList: any[] = [];

                                        service.map((service) => service.value).map((serviceID) => {
                                            Object.keys(services).map((key) => {


                                                services[key].filter((service: any) => service.id === serviceID).map((service: any) => {
                                                    console.log("Service: ", service)
                                                    serviceList.push(service);
                                                })


                                            })
                                        })

                                        const totalDuration = serviceList.reduce((total, service) => total + service.duration_minutes, 0);

                                        const isoDate = new Date(setHours(date, parseInt(time?.value.substring(0, 2)))
                                            .setMinutes(parseInt(time?.value.substring(2, 4)))).toISOString();

                                        const endTime = add(isoDate, { minutes: totalDuration }).toISOString();

                                        client.post("bookAppointment",
                                            {
                                                appointment: {
                                                    status: alreadyComplete ? "completed" : "accepted",
                                                    services: serviceList,
                                                    totalDurationMinutes: totalDuration,
                                                    businessID: selectedShop,
                                                    professionalID: staff?.value,
                                                    isoDate,
                                                    endTime,
                                                    appointmentType: aptType.value,
                                                    alreadyComplete: alreadyComplete
                                                },
                                                client: {
                                                    name: nameRef.current.getInputText() as string,
                                                    email: emailRef.current.getInputText() as string,
                                                    phone: phoneRef.current.getInputText() as string,
                                                }
                                            })

                                            .then((res) => {
                                                setLoading(false)
                                                resetFields();
                                                showToast("Appointment saved", "success");
                                                dispatch.Modals.toggleAddAppointmentModal(false);

                                            }).catch((e) => {
                                                console.log("Couldn't add appointment", e)
                                                showToast("Couldn't add appointment, please check your internet connexion and try again", "error")
                                                setLoading(false)
                                            }).finally(() => {
                                                setLoading(false)
                                            })

                                    } catch (e) {
                                        console.log("Couldn't add appointment. Error: ", e)
                                        setLoading(false);
                                        showToast("Couldn't add the appointment. Please try again.", "error")
                                    }
                                }} size={'sm'}>
                                    Add appointment
                                </Button>

                                <Button secondaryGray disabled={false} onClick={() => {
                                    toggleModal();
                                    resetFields();
                                    setLoading(false)
                                }} size={'sm'}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default AddAppointment